import React, { CSSProperties, SyntheticEvent, useCallback, useMemo, useState } from "react";

import { Button, ButtonProps, Icon, Popup } from "semantic-ui-react";

import { toast } from "react-toastify";

import SubBillLockNotiMessage, {
  BillLockNotiMessageItem,
} from "react-lib/apps/HISV3/BIL/SubBillLockNotiMessage";

// Types
type NotificationMessageProps = {
  onEvent: (e: any) => void;
  setProp: (key: string, value: any, callback?: () => void) => any;
  billingLockNotificationList?: Record<string, any>[];
  billLockNotiMessageList?: Record<string, any>[];
  showIconNoti?: boolean;
  tabPatientRef?: { changeTabKey: (tab: string, callback: () => void) => void };
  onClickBell?: () => void;
};

type Styles = Record<"box" | "button" | "buttonActive" | "icon" | "popup", CSSProperties>;

// Constants
const styles: Styles = {
  box: {
    backgroundColor: "unset",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.1)",
    margin: "0.1rem 0.25rem 1rem",
  },
  button: { width: "11rem" },
  buttonActive: { backgroundColor: "rgba(93, 188, 210, 0.88)", color: "white", width: "11rem" },
  icon: {
    color: "white",
    cursor: "pointer",
    fontSize: "1.4em",
  },
  popup: { minWidth: "43rem" },
};

const TITLES = {
  COMPLETED: "ดำเนินการแล้ว",
  PENDING: "ยังไม่ได้ดำเนินการ",
};

const POPUP_OFFSET: [number, number] = [8, 0];

const NotificationMessage = (props: NotificationMessageProps) => {
  const [tab, setTab] = useState<"COMPLETED" | "PENDING">("PENDING");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenPendingInvoice = useCallback(
    (data: BillLockNotiMessageItem) => () => {
      props.tabPatientRef?.changeTabKey("Encounter", () => {
        props.setProp("initialLayout", 1);

        props.onEvent({
          message: "BillingQueueAction",
          params: {
            action: "select_encounter",
            encounterId: data.encounter,
          },
        });

        handleClose();

        // ปิด toast ด้วยเมื่อมี toast เปิดค้างอยู่
        const messages = props.billLockNotiMessageList || [];
        const foundNoti = messages.find((item) => item.id === data.encounter);

        if (!foundNoti) {
          return;
        }

        toast.dismiss(foundNoti.time);

        const filttered = messages.filter((item) => item.id !== foundNoti.id);

        props.setProp("billLockNotiMessageList", filttered);
      });
    },
    [props.billLockNotiMessageList, props.tabPatientRef]
  );

  const handleChangeTab = useCallback((e: SyntheticEvent, data: ButtonProps) => {
    setTab(data.name);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(!props.showIconNoti);
  }, [props.showIconNoti]);

  const groupData = useMemo(() => {
    const list = props.billingLockNotificationList || [];

    return {
      COMPLETED: list.filter((item) => item.data.performed),
      PENDING: list.filter((item) => !item.data.performed),
    };
  }, [props.billingLockNotificationList]);

  const showIconNoti = useMemo(
    () => props.showIconNoti || groupData.PENDING.length > 0,
    [groupData, props.showIconNoti]
  );

  const trigger = useMemo(
    () => <Icon name="bell" style={styles.icon} onClick={props.onClickBell} />,
    []
  );

  const content = useMemo(
    () => (
      <div>
        <strong>
          <label style={{ fontSize: "1.2rem" }}>การแจ้งเตือน</label>
        </strong>
        <div style={{ display: "flex", margin: "0.75rem 0 1rem" }}>
          <Button
            name="PENDING"
            size="small"
            style={tab === "PENDING" ? styles.buttonActive : styles.button}
            onClick={handleChangeTab}
          >
            {TITLES.PENDING}
          </Button>
          <Button
            name="COMPLETED"
            size="small"
            style={tab === "COMPLETED" ? styles.buttonActive : styles.button}
            onClick={handleChangeTab}
          >
            {TITLES.COMPLETED}
          </Button>
        </div>
        <div style={{ height: "75dvh", overflowY: "auto" }}>
          {groupData[tab].length === 0 && (
            <div style={{ fontSize: "1.05rem", marginTop: "3rem", textAlign: "center" }}>
              ไม่มีรายการที่{" "}
              <strong>
                <span>{`"${TITLES[tab]}"`}</span>
              </strong>
            </div>
          )}
          {groupData[tab].map((item) => (
            <SubBillLockNotiMessage
              {...item.data}
              key={item.time}
              disabled={item.data.performed}
              style={styles.box}
              onOpenPendingInvoice={handleOpenPendingInvoice(item.data)}
            />
          ))}
        </div>
      </div>
    ),
    [groupData, handleOpenPendingInvoice, tab]
  );

  return (
    <div style={{ marginRight: "22px", position: "relative" }}>
      <Popup
        content={content}
        offset={POPUP_OFFSET}
        position="bottom right"
        style={styles.popup}
        trigger={trigger}
        on="click"
        onClose={handleClose}
        onOpen={handleOpen}
        open={
          props.showIconNoti || props.billingLockNotificationList?.length === 0 ? false : isOpen
        }
      />
      {showIconNoti && (
        <div
          style={{
            borderRadius: "50%",
            color: "red",
            fontSize: "0.75rem",
            fontWeight: "bold",
            height: "8px",
            position: "absolute",
            right: "6px",
            textAlign: "center",
            top: 0,
            width: "8px",
            ...(props.showIconNoti && { backgroundColor: "red", top: "2px" }),
          }}
        >
          {props.showIconNoti ? "" : groupData.PENDING.length}
        </div>
      )}
    </div>
  );
};

NotificationMessage.displayName = "NotificationMessage";

export default React.memo(NotificationMessage);
